@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*custom css*/

:root{
	--primary-font-family: 'Roboto',san-serif;
	--secondary-font-family: 'Montserrat',san-serif;
	--primary-text-color: rgb(33, 33, 33);
	--light-bg: #f8f8f8;
	--transition-time:0.3s;
	--red: #fe1232;
	--default-bg:#efefef;
	--light-blue: rgba(103, 153, 255, 0.05);
	--primary-top-right: rgba(252, 59, 59, 0.84);
	--primary-bottom-right: rgba(0, 27, 80, 0.55);
}
* {
	padding: 0;
	margin: 0;
}
html,
body {
	height: 100%;
	color: rgb(33, 33, 33);
	color: var(--primary-text-color);
	font-family: 'Roboto',san-serif;
	font-family: var(--primary-font-family);
	scroll-behavior: smooth;
	font-size: 14px;
	background-color: #efefef;
	background-color: var(--default-bg);
}


/* Track */

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	border-radius: 5px;
	background: linear-gradient(
			90deg, #434343, #434343 1px, #111 0, #111);
}

/* Handle */

::-webkit-scrollbar-thumb {
	background: #fe1232;
	background: var(--red);
	border-radius: 5px;
	box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background: #fefefe;
}

.navigation {
	background: #fefefe;
	border-bottom: 0.5px solid #efefef;
}
.logo .cls-1{fill:#231f20;}
.logo .cls-2{fill:#58595b;}
.logo .cls-3{fill:#414042;}
.logo .cls-4{fill:#fe1232;fill:var(--red);}
.logo .cls-5{fill:#6d6e71;}
.logo .cls-6{fill:#fe1232;fill:var(--red);}

.logo-toggle .cls-1{fill:#231f20;}
.logo-toggle .cls-2{fill:#58595b;}
.logo-toggle .cls-3{fill:#414042;}
.logo-toggle .cls-4{fill:#fe1232;fill:var(--red);}
.logo-toggle .cls-5{fill:#6d6e71;}
.logo-toggle .cls-6{fill:#fe1232;fill:var(--red);}

.telnet-logo-name{
	font-size: 24px;
	letter-spacing: -0.6px;
	font-family:'Montserrat',san-serif;
	font-family:var(--secondary-font-family);
	font-weight: 500;
	
}

.underline-header{
border-bottom: 4px solid rgba(254, 18, 50, 0.1);
}

.nav-link {
	text-transform: uppercase;
	letter-spacing: 0.3px;
	color:rgb(4 13 33 / 50%) !important;
	/*font-family: 'Fira Mono', monospace;*/
	font-size: 13px;
	transition: all 0.5s;
	padding: 10px 0px;
	font-weight: 400;
}

.nav-link:hover,
.nav-link:active {
	color: rgb(4 13 33 / 90%) !important;
	transition: all 0.5s;
	/*font-family: 'Fira Mono', monospace;*/
	font-size: 13px;
	font-weight: 400;
}


.welcome{
	background-color: #f8f8f8;
	background-color: var(--light-bg);
}

.section-header {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.6rem;
}
.service-tab{
	background: #ffffff;
	padding:1rem 0.5rem;
	border-radius: 1rem;
	width: 100%;
	box-shadow: 0px 5px 15px -10px #6d6d6d;
	transition-duration: 0.3s;
	transition-duration: var(--transition-time);
}
.service-tab:hover{
	box-shadow: 0px 5px 15px -15px #6d6d6d;
	transition-duration: 0.3s;
	transition-duration: var(--transition-time);
}
.service-description{
	list-style: none;
}
.service-description ul li{
 display: flex;
	justify-content: center;
	flex-direction: row;
}
.more{
	padding: 0.5rem 0.5rem;
	
}
.more p{
	display: none;
	float: right;
	background: rgba(0, 85, 255, 0.05);
	color: rgb(38, 75, 158);
	font-weight: 400;
	padding: 0.2rem 0.5rem;
	border-radius: 0.5rem;
}
.icon-bg {
	background-color: rgba(103, 153, 255, 0.05);
	background-color: var(--light-blue);
	padding: 1.2rem;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 10rem;
}
#about .highlight-text {
	border-bottom: 2px solid rgba(0, 66, 255, 0.2);
}
.owl-theme {
	/*height: 80vh;*/
	width: 100% !important;
	display: flex;
}
.img-title{
	text-align: center;
	color: #fefefe;
	background: #001734;
	padding: 0.5rem 0;
	font-size: 18px;
}
.team-tab{
	/*display: flex;*/
	width: 100%;
}
.team-profile{
	display: flex;
	width: 100%;
	border-radius: 0.5rem;
	background: #ffffff;
	box-shadow:   0px 5px 15px -10px #6d6d6d;
	transition-duration:0.3s;
	transition-duration:var(--transition-time);
}
.team-profile:hover{
	box-shadow:   0px 5px 15px -15px #6d6d6d;
	transition-duration:0.3s;
	transition-duration:var(--transition-time);
}
.team-name{
	font-size: 16px;
	font-weight: 500;
	color:rgb(38, 75, 158);
}
.team-description{
	font-size: 14px;
	color: rgb(33, 33, 33);
	color: var(--primary-text-color);

}
#contact{
	/*background: var(--light-bg);*/
}
address a {
color:rgb(33, 33, 33);
color:var(--primary-text-color);
}

address a:hover{
	color:rgba(0, 27, 80, 0.55);
	color:var(--primary-bottom-right);
}

a.download{
	text-transform: uppercase;
	letter-spacing: 0.9px;
	text-decoration: none;
	display: block;
	background: #001734;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	color: #f8f8f8;
	color: var(--light-bg);
	padding: 0.2rem 4rem;
	transition-duration: 0.3s;
	transition-duration: var(--transition-time);
	border: 1px solid #001734;
}
a.download:hover{
	transition-duration: 0.3s;
	transition-duration: var(--transition-time);
	background: rgba(103, 153, 255, 0.05);
	background: var(--light-blue);
	text-decoration: none;
	color: #001734;
}
.telnet-works{
	display: flex;
	width: 100%;
	border-radius: 0.5rem;
	background: #ffffff;
	box-shadow:   0px 5px 15px -10px #6d6d6d;
	transition-duration:0.3s;
	transition-duration:var(--transition-time);
}
.telnet-works:hover{
	box-shadow:   0px 5px 15px -15px #6d6d6d;
	transition-duration:0.3s;
	transition-duration:var(--transition-time);
}
.telnet-works p{
	margin: 0 !important;
}
.telnet-works .client{
	font-weight: 600;
	font-size: 110%;
}
.telnet-works .work{
}
.telnet-works .year{
	font-weight: 600;
	font-size: 14px;
}
#products{
	background-color: #f8f8f8;
	background-color: var(--light-bg);
}
.line-separator{
	border-bottom: 1px solid #dedede;
}

.animated-svg .cls-1{
	fill: none;
	stroke:#000;
	stroke-miterlimit:10;
}
.animated-svg .cls-2{
	 fill: #fe1232;
	 fill: var(--red);
 }
#sample .cls-1 {
	fill: none;
	stroke: #231f20;
	stroke-linejoin: round;
}
